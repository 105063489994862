import { PostFeed } from 'src/components/common/PostFeed';
import { Community, CommunityReport, Opportunity } from "src/types/models";
import { createApi } from ".";
import {
  STT_CREATED,
  STT_EMPTY,
  STT_OK,
  STT_UNAUTHORIZED,
} from "src/const/app.const";
import toast from 'react-hot-toast';
import { Poll } from "src/components/community/constants/community.models";

interface IJoinCommunity {
  country: string;
  zipcode: string;
}

interface IGetCommunity {
  id?: string;
  businessId?: string;
}

interface CommunityReportResponse {
  liveReward: number;
  nextDrawing: string;
  report: CommunityReport;
 // reportRecords: CommunityReport[];
  topOpportunity: Opportunity;
}

interface PollResponse {
  currentMonthPoll: Poll;
  previousMonthPoll: Poll;
}

interface CommunityChangeResponse {
  communityMember: {
    data: Community;
  };
}

export interface ICommunityPrize {
  vote: string;
  amountWon: number;
  createdAt: string;
  winnerId: number;
  winnerName: string;
  zipcode: string;
  country: string;
}

interface iNewsPost {
  zipcode: string;
  title: string;
  description: string;
  image?: string;
  businessId: string;
  businessName: string;
  tagList?:object;
}

interface canBusinessPost {
  businessId: string;
  communityId: string;
}

interface iUserPost {
  zipcode: string;
  country: string;
  title: string;
  description: string;
  image?: string;
  tagList?:object;
}

interface UpdatePostData {
  id: string;
  title?: string;
  description?: string;
  image?: string;
  isVisible?: boolean;
  tagList?:object;
}

interface DeletePostData {
  id: string;
}

export const joinCommunity = (data: IJoinCommunity) =>
  createApi().post("/community", data);

export const getCommunity = async (
  id: string,
  isBusiness: boolean
): Promise<any> => {
  const data: IGetCommunity = isBusiness ? { businessId: id } : { id };
  return await createApi().get("/community", data);
};

export const getUserPoll = async (): Promise<PollResponse | undefined> => {
  const res = await createApi().get<{
    results: PollResponse;
    message?: string;
  }>("/polls");
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.originalError}`);
    return;
  }
  return res.data.results;
};


export const setCommunityLastTimeCheck = async (businessId?: string) => {
  const requestBody = businessId ? { businessId: businessId } : {};
  const res = await createApi().post("/community/visit", requestBody);
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.originalError}`);
    return;
  }
  return res;
};

export const getCommunityReport = async (
  communityId: string,
  businessId?: string
): Promise<CommunityReportResponse | undefined> => {
  const res = await createApi().get<{
    results: CommunityReportResponse;
    message?: string;
  }>(
    `/community/report/${communityId}${
      businessId ? `?businessId=${businessId}` : ""
    }`
  );

  if (res.status !== STT_OK || !res.data) {
    return;
  }
  return res.data.results;
};

export const getCommunityReportWithFeedCheck = async (
  communityId: string,
  reportId: string
): Promise<CommunityReportResponse | undefined> => {
  const res = await createApi().get<{
    results: CommunityReportResponse;
    message?: string;
  }>(`/community/report/check/${communityId}?reportId=${reportId}`);

  if (res.status !== STT_OK || !res.data) {
    return;
  }
  return res.data.results;
};


export const createPoll = async (
  inputData: any
): Promise<PollResponse | undefined> => {
  const res = await createApi().post<{
    results: PollResponse;
    message?: string;
  }>("/polls", inputData);
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res?.data?.message || res.originalError}`);
    return;
  }
  toast.success("Great! You have voted.");
  return res.data.results;
};

export const runPollWinner = async (): Promise<{ message: string } | undefined> => {
  const res = await createApi().post<{ message: string }>("/run-poll-winner");

  if (res.status !== STT_OK || !res.data) {
    toast.error(res.data?.message || "Failed to run poll winner job.");
    return;
  }

  toast.success(res.data.message || "Poll winner job finished successfully.");
  return res.data;
};

export const changeCommunity = async (
  data: any
): Promise<CommunityChangeResponse | undefined> => {
  const res = await createApi().put<any>("/community", data);
  if (res.status !== STT_OK || !res.data) {
    toast.success(`${res?.data?.message || res.originalError}`);
    return;
  }
  toast.success("Exciting! You have moved to a new community.");
  return res.data;
};

export const getCommunityFeed = async (communityId: string, data: { limit: number; offset: number }) => {
  const res = await createApi().get<any>(`/community-feed/${communityId}`,data);

  if (res.status !== STT_OK || !res.data) {
    //toast.error(`Error: ${res.originalError}`);
    return;
  }
  return res.data;
};

export const getSinglePost = async (postId: string, businessId: string) => {
  const res = await createApi().get<{data: PostFeed}>(`/feed/${postId}?businessId=${businessId}`);
  // const res = await createApi().get<{data: PostFeed}>(`/feed/${postId}`, { businessId });
  if (res.status !== STT_OK || !res.data) {
    // toast.error(`Error: ${res.originalError}`);
    return null;
  }
  return res.data;
};


export const allowedUser = async (communityId: string, data: { businessId: string }) => {
  const res = await createApi().get<any>(`/community-access/${communityId}`,data);
  if (res.status !== STT_OK ) {
    //toast.error(`Error: ${res.originalError}`);
    return false;
  }
  return true;
};

export const getCommunityPrizes = async (data: {
  limit: number;
  offset: number;
}) => {
  try {
    const res = await createApi().get<{
      data: ICommunityPrize[];
      total: number;
    }>(`/community-prize`, data);

    if (res.status === STT_EMPTY) {
      return { prize: [], total: 0 };
    }

    if (res.status === STT_UNAUTHORIZED) {
      toast.error(res.status);
      return { prize: [], total: 0 };
    }

    if (!res.data) {
      toast.error(res.status);
      return { prize: [], total: 0 };
    }
    return {
      total: res.data.total,
      prizeList: res.data.data,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createNewsPost = async (data: iNewsPost) => {
  const res = await createApi().post<any>("/feed/business", data);

  if (res.status !== STT_CREATED) {
    toast.error(res.data?.message || "Something went wrong, please try again later.");
    return false;
  }

  toast.success("Exciting! Your post has been published.");
  return res.data;
};

export const businessPostPermission = async (data : canBusinessPost) => {
  const res = await createApi().post<any>("/business/permission/post", data);
  if (res.status !== STT_OK) {
    return false;
  }
  return true;
};

export const createUserPost = async (data: iUserPost) => {
  const res = await createApi().post<any>("/feed", data);

  if (res.status !== STT_CREATED) {
    toast.error(res.data?.message || "Something went wrong, please try again later.");
    return false;
  }

  toast.success("Exciting! Your post has been published.");
  return res.data;
};

export const getApost = async (id: string) => {
  const res = await createApi().get<any>(`/feed/${id}`);

  if (res.status !== STT_CREATED) {
    toast.error(res.data?.message || "Something went wrong, please try again later.");
    return false;
  }

  toast.success("Exciting! Your post has been published.");
  return res.data;
};

export const updatePost = async (data: UpdatePostData): Promise<boolean> => {
  const res = await createApi().put<{message?: string}>("/feed", data);

  if (res.status !== STT_OK) {
    toast.error(res.data?.message || "Something went wrong, please try again later.");
    return false;
  }

  toast.success("Your post has been edited.");
  return true;
};

export const sharePost = async (data: {
  postId: string;
  postTitle: string;
  receiverId: string;
}) => {
  const res = await createApi().post<{ result: PostFeed; postTitle: string, message?: string }>(
    "/feed/share",
    data
  );
  if (res.status !== STT_OK) {
    toast.error(`${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};

interface IDeletePost{
  id: string;
  businessId?: string;
}
export const deletePost = async (data : IDeletePost, isDeletedOpportunity = false): Promise<boolean> => {
  const res = await createApi().delete<{message?: string}>("/feed", {}, { data });

  if (res.status !== STT_OK) {
    toast.error(res.data?.message || "Something went wrong, please try again later.");
    return false;
  }

  if(!isDeletedOpportunity) {
    toast.success("Your post has been deleted.");
  }
  return true;
};

export const getFeedByAuthorId = async (authorId: string, data: { limit: number; offset: number; businessId: string; currentUserId: string }) => {
  const res = await createApi().get<any>(`/feed/author/${authorId}`, { params: data });

if ((res.status !== STT_OK) && res.status !== STT_UNAUTHORIZED) {
  toast.error(`${res.data?.message || res.originalError}`);
  return; }
  return res.data;
};


export const getFeedByOpportunityId = async (opportunityId: string) => {
  const res = await createApi().get<any>(`/community-feed/opportunity/${opportunityId}`);

if (res.status !== STT_OK) {
  toast.error(`${res.data?.message}`);
  return; }
  return res.data;
};

