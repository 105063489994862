import Head from "next/head";
import { useRouter } from "next/router";
import { LoginModal, SignUpModal } from "../Modal";
import { AccountContext, type AccountContextType } from "../contexts/ToggleAccountContext";
import Footer from "../Footer";
import SimpleNav from "src/components/nav/SimpleNav";
import TabBarMobile from "../TabBarMobile";
import BreadCrumbNav, { BreadCrumbNavProps } from "../nav/BreadCrumbNav";
import useMobile from "src/hooks/useMobile";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMailBox } from "src/redux/message.reducer";
import { AppState } from "src/store";
import * as MessageService from "src/services/messages.service";
import { COMMON_X_MARGIN } from "src/const/app.const";
import Script from "next/script";
import { usePageContext } from "../contexts/PageContext";
import { env } from "src/env/client.mjs";
import { NextSeo } from "next-seo";

type PageContainerProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  image?: string;
  scroll?: boolean;
};

const GoogleAnalytics = () => {
  const PUBLIC_KEY = "G-5F8YX8LQKB";

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${PUBLIC_KEY}`}
      />

      <Script strategy="lazyOnload" id="google-analytics">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${PUBLIC_KEY}', {
            page_path: window.location.pathname,
            });
        `}
      </Script>
    </>
  );
};

/**
 * We want this enabled only in production environment
 */
const ClarityAnalytics = () => {
  if (!env.NEXT_PUBLIC_CLARITY_KEY) {
    return null;
  }

  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${env.NEXT_PUBLIC_CLARITY_KEY}");`,
      }}
    ></script>
  );
};
export const PageContainer: React.FC<PageContainerProps> = ({ children, ...customMeta }) => {
  const { isHiddenHeader, breadcrumb, pageTitle, isHiddenFooter } = usePageContext();
  const router = useRouter();
  const [isShowSignUp, setShowSignUp] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hiddenHeader, setHiddenHeader] = useState(isHiddenHeader);
  const [hiddenFooter, setHiddenFooter] = useState(isHiddenFooter);
  const [title, setPageTitle] = useState<string>(pageTitle);
  const [currentBreadcrumb, setBreadcrumb] = useState<BreadCrumbNavProps>(breadcrumb);
  const mobile = useMobile();
  const noScrollUrl = ["/account/wallet", "/messages", "/account/social/info"];
  const [isReady, setIsReady] = useState(false);
  const { isBusiness, setShowLogin, isShowLogin, accountInfo } =
    useContext<AccountContextType>(AccountContext);
  const meta = {
    title: customMeta.title || "Daily Deals & Events - MindKind",
    description:
      customMeta.description ||
      "Find the best opportunities your neighborhood has to offer. Belong in your community with MindKind.",
    image: customMeta.image || "/media-logo.png",
    type: "website",
  };

  useEffect(() => {
    setHiddenHeader(isHiddenHeader);
    setHiddenFooter(isHiddenFooter);
    setBreadcrumb(breadcrumb);
    setPageTitle(pageTitle);
  }, [pageTitle, breadcrumb, isHiddenHeader, isHiddenFooter, isMobile]);

  // Avoid Hydration issue
  useEffect(() => {
    setIsMobile(mobile);
    setIsReady(true);
  }, [mobile]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (noScrollUrl.includes(url)) {
        document.body.classList.add("hide-scroll");
        return;
      }
      document.body.classList.remove("hide-scroll");
    };

    // Add event listener for route changes
    router.events.on("routeChangeComplete", handleRouteChange);

    // Cleanup event listener when component unmounts
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (customMeta.scroll === true) {
      document.body.classList.remove("hide-scroll");
    }
  }, [customMeta.scroll]);

  const homeFilters = router.route === "/";
  const hideNavPaths = ["/community", "/account", "/messages", "/opportunities", "/giftcards"];
  const shouldHideNav = hideNavPaths.includes(router.pathname) || router.pathname.startsWith("/merchant");
  
  const marginTop = isMobile && shouldHideNav ? "mt-[0px]" : (homeFilters && isMobile ? "mt-[48px]" : "mt-[73px]");

  return (
    <div className="min-h-screen w-full bg-gray-50 dark:bg-gray-900  ">
      <GoogleAnalytics />
      <ClarityAnalytics />
      <NextSeo
        title={meta.title}
        description={meta.description}
        openGraph={{
          images: [
            {
              url: meta.image,
            },
          ],
        }}
      />
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />
        <meta property="og:url" content={`https://mindkind.life${router.asPath}`} />
        <link rel="canonical" href={`https://mindkind.life${router.asPath}`} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="Mindkind" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <link rel="apple-touch-icon" type="image/png" href="/logowhitebg.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap"
          rel="stylesheet"
        ></link>
      </Head>

      {isMobile && !currentBreadcrumb?.label && !hiddenHeader && isReady && (
        <SimpleNav setShowSignUp={setShowSignUp} pageTitle={title} />
      )}
      {!isMobile && !hiddenHeader && isReady && (
        <SimpleNav setShowSignUp={setShowSignUp} pageTitle={title} />
      )}
      {isReady && isMobile && currentBreadcrumb?.label && !hiddenHeader && (
        <BreadCrumbNav {...currentBreadcrumb} />
      )}
      <main
        className={
          !hiddenHeader ? `${COMMON_X_MARGIN} ${marginTop} min-h-[calc(100%-130px)] w-auto` : ""
        }
      >
        {children}
        <LoginModal
          onSignUp={() => setShowSignUp(true)}
          open={isShowLogin}
          onClose={() => setShowLogin(false)}
        />
        <SignUpModal
          open={isShowSignUp}
          onClose={() => setShowSignUp(false)}
          onLogin={setShowLogin}
        />
      </main>
      {isMobile && !breadcrumb?.label && !hiddenHeader && <TabBarMobile />}
      {!hiddenHeader && !hiddenFooter && <Footer />}
    </div>
  );
};
