/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment";
import router from "next/router";
import React, { useState, createContext, useEffect, useContext } from "react";
import { AccountService } from "src/services";

export const STORAGE_TOKEN = "token";
export const STORAGE_EXPIRATION_TIME = "token-expiration-time";
export const STORAGE_GENERATION_TIME = "token-generation-time";
export const STORAGE_BUSINESS = "business"; // Selected business
export const STORAGE_ACCOUNT = "account";

// Common for User and Business
export type AccountInfoType = {
  [x: string]: any;
  id: string;
  name: string;
  email: string;
  username: string;
  password?: string;
  number: string;
  image?: string;
  description?: string;
  website?: string;
  referralCode?: string;
  community: {
    community_is_active: boolean;
    community_zipCode: string;
    country: string;
  };
  lastTimeCheckCommunity: Date;
  lastTimeCheckMessages: Date;
};

export type AccountContextType = {
  // TODO: Check to refactor
  isShowLogin: boolean;
  setShowLogin: (value: boolean) => void;

  logout: () => void;
  login: (data: AccountInfoType & { accessToken: string; expiration: Date }) => void;
  selectBusiness: (info: AccountInfoType | false) => void;
  updateUserInfo: (info: AccountInfoType) => void;
  updateBusinessInfo: (info: AccountInfoType) => void;

  isLogged: boolean;
  isBusiness: boolean;
  accountInfo: AccountInfoType | false; // Selected Profile
  userInfo: AccountInfoType | false; // User Log info

  checkHasNewFeed: boolean; // user account
  setCheckHasNewFeed: (value: boolean) => void; // user account

  checkHasNewFeedBusiness: boolean;
  setCheckHasNewFeedBusiness: (value: boolean) => void;

  businessFeedStatus: { [key: string]: boolean }; // Track feed status for multiple businesses
  setBusinessFeedStatus: (id: string, value: boolean) => void; // Update feed status for specific business

  //Message checks
  // User account
  unreadMessages: boolean;
  setUnreadMessages: (value: boolean) => void;

  //Business account
  checkHasNewMessageBusiness: boolean;
  setCheckHasNewMessageBusiness: (value: boolean) => void;
  businessMessageStatus: { [key: string]: boolean };
  setBusinessMessageStatus: (id: string, value: boolean) => void;
};

// Create a context object
export const AccountContext = createContext<AccountContextType>({
  isShowLogin: false,
  setShowLogin: () => {},

  logout: () => {},
  login: () => {},
  selectBusiness: () => 0,
  updateUserInfo: () => 0,
  updateBusinessInfo: () => 0,

  isLogged: false,
  isBusiness: false,
  accountInfo: false, // Selected Account
  userInfo: false,

  checkHasNewFeed: false, // user state for has new community feed
  setCheckHasNewFeed: () => {}, // user state for has new community feed

  checkHasNewFeedBusiness: false,
  setCheckHasNewFeedBusiness: () => {},

  businessFeedStatus: {}, // Empty object to track business feed statuses
  setBusinessFeedStatus: () => {}, // Function to set feed status for a specific business

  // Messages check
  // User account
  unreadMessages: false,
  setUnreadMessages: () => {},
  // Business
  checkHasNewMessageBusiness: false,
  setCheckHasNewMessageBusiness: () => {},
  businessMessageStatus: {},
  setBusinessMessageStatus: () => {},
});

export const useAccountContext = () => useContext(AccountContext);

// Create a provider for the context object
export const AccountProvider = ({ children }: any) => {
  // Set the initial value for the context object
  const [isReady, setIsReady] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<AccountInfoType | false>(false);
  const [businessInfo, setBusinessInfo] = useState<Partial<AccountInfoType> | false>(false);
  const [isShowLogin, setShowLogin] = useState(false);
  const [checkHasNewFeed, setCheckHasNewFeed] = useState<boolean>(false);
  // Business check
  const [checkHasNewFeedBusiness, setCheckHasNewFeedBusiness] = useState<boolean>(false);
  const [updatedBusinessInfo, setUpdatedBusinessInfo] = useState<AccountInfoType | false>(false);
  const [businessFeedStatus, setBusinessFeedStatusState] = useState<{ [key: string]: boolean }>(
    () => {
      // Lazy initialization to retrieve the state from localStorage only on the first render
      if (typeof window !== "undefined") {
        const savedStatus = localStorage.getItem("businessFeedStatus");
        return savedStatus ? JSON.parse(savedStatus) : {};
      }
      return {};
    },
  );
  // Messages
  //Users
  const [unreadMessages, setUnreadMessages] = useState<boolean>(false);
  // Business
  const [checkHasNewMessageBusiness, setCheckHasNewMessageBusiness] = useState<boolean>(false);
  const [businessMessageStatus, setBusinessMessageStatusState] = useState<{
    [key: string]: boolean;
  }>(() => {
    // Lazy initialization to retrieve the state from localStorage only on the first render
    if (typeof window !== "undefined") {
      const savedStatus = localStorage.getItem("businessMessageStatus");
      return savedStatus ? JSON.parse(savedStatus) : {};
    }
    return {};
  });

  /** Refresh Token */
  useEffect(() => {
    if (!userInfo) {
      return;
    }

    const tokenGenerated = localStorage.getItem(STORAGE_GENERATION_TIME);
    if (!tokenGenerated) {
      return;
    }

    // If is 1 day since we generated the token
    if (moment().isBefore(moment(+tokenGenerated).add(1, "day"))) {
      return;
    }

    AccountService.refreshAuth().then((response) => {
      if (!response) {
        return logout();
      }
      const { accessToken, expiration } = response;
      localStorage.setItem(STORAGE_TOKEN, accessToken);
      localStorage.setItem(
        STORAGE_EXPIRATION_TIME,
        moment(expiration).toDate().getTime().toString(),
      );
      localStorage.setItem(STORAGE_GENERATION_TIME, moment().toDate().getTime().toString());
    });
  }, [userInfo, updatedBusinessInfo]);
  // Load the information from the localstorage
  useEffect(() => {
    // Check Token expiration time
    const tokenExpirationDate = localStorage.getItem(STORAGE_EXPIRATION_TIME);
    if (tokenExpirationDate && +tokenExpirationDate < Date.now()) {
      logout();
      setIsReady(true);
      return;
    }
    // If the token Expiration Date is within 2 days, we will renovate the token here async

    const userInfoData = JSON.parse(localStorage.getItem(STORAGE_ACCOUNT) || "null");
    userInfoData && setUserInfo(userInfoData);

    const businessInfoData = JSON.parse(localStorage.getItem(STORAGE_BUSINESS) || "null");
    businessInfoData && setBusinessInfo(businessInfoData);

    setIsReady(true);
  }, []);

  const logout = () => {
    localStorage.removeItem(STORAGE_ACCOUNT);
    localStorage.removeItem(STORAGE_TOKEN);
    localStorage.removeItem(STORAGE_BUSINESS);
    localStorage.removeItem(STORAGE_EXPIRATION_TIME);
    setUserInfo(false);
    setBusinessInfo(false);
    router.replace("/");
  };

  const login = (data: AccountInfoType & { accessToken: string; expiration: Date }) => {
    const { accessToken, expiration, ...userInfo } = data;
    localStorage.setItem(STORAGE_TOKEN, accessToken);
    localStorage.setItem(STORAGE_EXPIRATION_TIME, moment(expiration).toDate().getTime().toString());
    localStorage.setItem(STORAGE_GENERATION_TIME, moment().toDate().getTime().toString());
    localStorage.setItem(STORAGE_ACCOUNT, JSON.stringify(userInfo));
    setUserInfo(userInfo);
  };

  const updateUserInfo = (userInfo: AccountInfoType) => {
    localStorage.setItem(STORAGE_ACCOUNT, JSON.stringify(userInfo));
    setUserInfo(userInfo);
  };

  const updateBusinessInfo = (accountInfo: AccountInfoType) => {
    localStorage.setItem(STORAGE_BUSINESS, JSON.stringify(accountInfo));
    setBusinessInfo(accountInfo);
  };

  const selectBusiness = (data: AccountInfoType | false) => {
    if (data === false) {
      localStorage.removeItem(STORAGE_BUSINESS);
    } else {
      localStorage.setItem(STORAGE_BUSINESS, JSON.stringify(data));
    }
    setBusinessInfo(data || false);
  };

  // We wait for isReady. So we are user is logged or not
  const isLogged = !isReady || (userInfo && !!userInfo.id);
  const isBusiness = isReady && businessInfo && !!businessInfo.id;
  const getAccountInfo = () => {
    if (!isLogged) {
      return false;
    }
    return (isBusiness ? businessInfo : userInfo) as AccountInfoType;
  };

  const setBusinessFeedStatus = (id: string, value: boolean) => {
    setBusinessFeedStatusState((prevStatus) => {
      const newStatus = { ...prevStatus, [id]: value };

      // Persist updated feed status to localStorage
      localStorage.setItem("businessFeedStatus", JSON.stringify(newStatus));

      return newStatus;
    });
  };
  const setBusinessMessageStatus = (id: string, value: boolean) => {
    setBusinessMessageStatusState((prevStatus) => {
      const newStatus = { ...prevStatus, [id]: value };

      // Persist updated feed status to localStorage
      localStorage.setItem("businessMessageStatus", JSON.stringify(newStatus));

      return newStatus;
    });
  };

  const accountInfo = getAccountInfo();

  return (
    <AccountContext.Provider
      value={{
        isShowLogin,
        setShowLogin,
        logout,
        login,
        isLogged,
        isBusiness,
        userInfo,
        updatedBusinessInfo,
        accountInfo,
        selectBusiness,
        updateUserInfo,
        updateBusinessInfo,
        businessInfo,
        checkHasNewFeed,
        setCheckHasNewFeed,
        checkHasNewFeedBusiness,
        setCheckHasNewFeedBusiness,
        businessFeedStatus,
        setBusinessFeedStatus,
        unreadMessages,
        setUnreadMessages,
        setBusinessMessageStatus,
        checkHasNewMessageBusiness,
        setCheckHasNewMessageBusiness,
        businessMessageStatus,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
