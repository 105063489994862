import { OpportunityPreview } from "../../types/models";
import OpportunityCard from "./OpportunityCard";

export default function HomeCard({
  opportunity,
  imagePriority,
  onSaveScroll,
}: {
  opportunity: OpportunityPreview;
  imagePriority: boolean;
  onSaveScroll: () => void;
}) {
  return (
    <OpportunityCard 
      opportunity={opportunity} 
      imagePriority={imagePriority} 
      onSaveScroll={onSaveScroll} 
    />
  );
}