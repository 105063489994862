import Link from "next/link";
import { useRef, useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { AppState } from "src/store";
import React, { useMemo, useContext } from "react";
import { AccountContext, AccountContextType } from "./contexts/ToggleAccountContext";
import { CommunityService } from "src/services";

const TabBarMobile = () => {
  const router = useRouter();
  const {
    isBusiness,
    accountInfo,
    userInfo,
    checkHasNewFeed,
    setCheckHasNewFeed,
    businessMessageStatus,
  } = useContext<AccountContextType>(AccountContext);
  const navRef = useRef(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const checkHasNew = useCallback(async () => {
    if (!accountInfo || isBusiness) {
      return;
    }
    const data = {
      limit: 1,
      offset: 0,
      businessId: isBusiness ? accountInfo.id : undefined,
    };
    const community = await CommunityService.getCommunity(accountInfo.id, isBusiness);
    if (!community.data[0]) {
      return;
    }
    const communityFeedData = await CommunityService.getCommunityFeed(
      community?.data[0].communityId,
      data,
    );

    if (communityFeedData?.feed[0].createdAt > userInfo?.lastTimeCheckCommunity) {
      setCheckHasNewFeed(true);
      return;
    }
    setCheckHasNewFeed(false);
    //dispatch(updateMailBox({ amount: data.newMessages }));
  }, [accountInfo, isBusiness]);

  useEffect(() => {
    checkHasNew();
  }, [accountInfo]);

  const activeIndex = useMemo(() => {
    if (router.isReady) {
      if (["/community"].includes(router.pathname)) {
        return 1;
      } else if (["/opportunities"].includes(router.pathname)) {
        return isBusiness ? 2 : 0;
      } else if (["/account"].includes(router.pathname)) {
        return 3;
      } else if (["/messages"].includes(router.pathname)) {
        return 4;
      } else if (["/"].includes(router.pathname) || router.pathname.search("merchant") > -1) {
        return isBusiness ? 0 : 2;
      } else {
        return null;
      }
    }
    return 2;
  }, [router.pathname, router.isReady]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(currentScrollPos <= 0 || prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const unreadMessages = useSelector((state: AppState) => state.message.unreadMessages);

  return (
    <nav
      ref={navRef}
      className={`fixed bottom-0 z-40 flex h-[70px] w-full items-center justify-between border-t-[#9B9A9A] bg-[#f8f8f8] transition duration-300 md:hidden ${
        visible ? "opacity-100" : "opacity-0"
      }`}
    >
      {isBusiness ? (
        <TabItems active={activeIndex === 0} label="Validate" link={`/merchant/${accountInfo?.id}`}>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 0.5C6.1 0.5 0.5 6.1 0.5 13C0.5 19.9 6.1 25.5 13 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 13 0.5ZM13 23C7.4875 23 3 18.5125 3 13C3 7.4875 7.4875 3 13 3C18.5125 3 23 7.4875 23 13C23 18.5125 18.5125 23 13 23ZM18.7375 7.475L10.5 15.7125L7.2625 12.4875L5.5 14.25L10.5 19.25L20.5 9.25L18.7375 7.475Z"
              fill={activeIndex === 0 ? "#EAAC00" : "black"}
            />
          </svg>
        </TabItems>
      ) : (
        <TabItems active={activeIndex === 0} label="Redeem" link="/opportunities">
          <svg
            width="26"
            height="26"
            viewBox="0 -960 960 960"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M120-520v-320h320v320H120Zm80-80h160v-160H200v160Zm-80 480v-320h320v320H120Zm80-80h160v-160H200v160Zm320-320v-320h320v320H520Zm80-80h160v-160H600v160Zm160 480v-80h80v80h-80ZM520-360v-80h80v80h-80Zm80 80v-80h80v80h-80Zm-80 80v-80h80v80h-80Zm80 80v-80h80v80h-80Zm80-80v-80h80v80h-80Zm0-160v-80h80v80h-80Zm80 80v-80h80v80h-80Z"
              fill={activeIndex === 0 ? "#EAAC00" : "black"}
            />
          </svg>
        </TabItems>
      )}
      <TabItems active={activeIndex === 1} label="Community" link="/community">
        <div className="relative">
          <svg
            width="25"
            height="25"
            viewBox="0 0 36 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 39.0002C2.2 39.0002 1.5 38.7002 0.9 38.1002C0.3 37.5002 0 36.8002 0 36.0002V26.1002L6.75 18.6502L8.9 20.8002L3 27.2502H33L27.45 20.9502L29.6 18.8002L36 26.1002V36.0002C36 36.8002 35.7 37.5002 35.1 38.1002C34.5 38.7002 33.8 39.0002 33 39.0002H3ZM3 36.0002H33V30.2502H3V36.0002ZM16.1 23.7502L8.15 15.8002C7.51667 15.1669 7.23333 14.4585 7.3 13.6752C7.36667 12.8919 7.7 12.2002 8.3 11.6002L18.9 1.00019C19.4667 0.433521 20.1667 0.141854 21 0.125187C21.8333 0.108521 22.55 0.400187 23.15 1.00019L31.1 8.95019C31.6667 9.51685 31.9583 10.1919 31.975 10.9752C31.9917 11.7585 31.6667 12.4835 31 13.1502L20.4 23.7502C19.8333 24.3169 19.1333 24.6169 18.3 24.6502C17.4667 24.6835 16.7333 24.3835 16.1 23.7502ZM28.55 10.9002L21.05 3.40019L10.65 13.8002L18.15 21.3002L28.55 10.9002ZM3 36.0002V30.2502V36.0002Z"
              fill={activeIndex === 1 ? "#EAAC00" : "black"}
            />
          </svg>
          {/* Dot for notifications */}
          {checkHasNewFeed && (
            <div className="brand-color absolute -right-2 -top-1 inline-flex h-3 w-3 rounded-full">
              {/* {unreadMessages} */}
            </div>
          )}
        </div>
      </TabItems>
      {isBusiness ? (
        <TabItems active={activeIndex === 2} label="Home" link="/opportunities">
          <svg
            width="26"
            height="26"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_696_56)">
              <path
                d="M1.25732 17H32.5409"
                strokeWidth="1.99358"
                stroke={activeIndex === 2 ? "#EAAC00" : "black"}
              />
              <path
                d="M17 32.7393L17 1.45924"
                stroke={activeIndex === 2 ? "#EAAC00" : "black"}
                strokeWidth="1.99358"
              />
              <path
                d="M0.630371 0.630371H33.2704V33.2704H0.630371V0.630371Z"
                stroke={activeIndex === 2 ? "#EAAC00" : "black"}
                strokeWidth="3.98831"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_56">
                <rect width="34" height="34" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </TabItems>
      ) : (
        <TabItems active={activeIndex === 2} label="Home" link="/">
          <svg
            width="26"
            height="26"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_696_56)">
              <path
                d="M1.25732 17H32.5409"
                strokeWidth="1.99358"
                stroke={activeIndex === 2 ? "#EAAC00" : "black"}
              />
              <path
                d="M17 32.7393L17 1.45924"
                stroke={activeIndex === 2 ? "#EAAC00" : "black"}
                strokeWidth="1.99358"
              />
              <path
                d="M0.630371 0.630371H33.2704V33.2704H0.630371V0.630371Z"
                stroke={activeIndex === 2 ? "#EAAC00" : "black"}
                strokeWidth="3.98831"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_56">
                <rect width="34" height="34" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </TabItems>
      )}

      <TabItems active={activeIndex === 4} label="Messages" link={"/messages"}>
        <div className="relative">
          <svg width="26" height="26" viewBox="0 0 40 32" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 32C2.2 32 1.5 31.7 0.9 31.1C0.3 30.5 0 29.8 0 29V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H37C37.8 0 38.5 0.3 39.1 0.9C39.7 1.5 40 2.2 40 3V29C40 29.8 39.7 30.5 39.1 31.1C38.5 31.7 37.8 32 37 32H3ZM20 16.9L3 5.75V29H37V5.75L20 16.9ZM20 13.9L36.8 3H3.25L20 13.9ZM3 5.75V3V29V5.75Z"
              stroke={activeIndex === 4 ? "#EAAC00" : "black"}
              fill={activeIndex === 4 ? "#EAAC00" : "black"}
            />
          </svg>
          {((isBusiness && businessMessageStatus[accountInfo.id]) || (!isBusiness && unreadMessages > 0)) && (
           <div className="brand-color absolute -right-2 -top-1 inline-flex h-3 w-3 rounded-full">
            {/* {unreadMessages} */}
          </div>
           )}
        </div>
      </TabItems>

      {isBusiness ? (
        <TabItems active={activeIndex === 3} label="Account" link="/account">
          <svg
            width="26"
            height="26"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 1.875V0H22.0625V1.875H2ZM2.15625 20V11.9375H0.625V10.0625L2 3.75H22.0312L23.4062 10.0625V11.9375H21.875V20H20V11.9375H14.0938V20H2.15625ZM4.03125 18.125H12.2188V11.9375H4.03125V18.125ZM2.46875 10.0625H21.5625H2.46875ZM2.46875 10.0625H21.5625L20.5938 5.625H3.4375L2.46875 10.0625Z"
              fill={activeIndex === 3 ? "#EAAC00" : "black"}
            />
          </svg>
        </TabItems>
      ) : (
        <TabItems active={activeIndex === 3} label="Account" link="/account">
          <svg
            width="26"
            height="26"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 15.9502C13.8 15.9502 12 15.2502 10.6 13.8502C9.2 12.4502 8.5 10.6502 8.5 8.4502C8.5 6.2502 9.2 4.4502 10.6 3.0502C12 1.6502 13.8 0.950195 16 0.950195C18.2 0.950195 20 1.6502 21.4 3.0502C22.8 4.4502 23.5 6.2502 23.5 8.4502C23.5 10.6502 22.8 12.4502 21.4 13.8502C20 15.2502 18.2 15.9502 16 15.9502ZM0 32.0002V27.3002C0 26.0335 0.316666 24.9502 0.95 24.0502C1.58333 23.1502 2.4 22.4669 3.4 22.0002C5.63333 21.0002 7.775 20.2502 9.825 19.7502C11.875 19.2502 13.9333 19.0002 16 19.0002C18.0667 19.0002 20.1167 19.2585 22.15 19.7752C24.1833 20.2919 26.3167 21.0335 28.55 22.0002C29.5833 22.4669 30.4167 23.1502 31.05 24.0502C31.6833 24.9502 32 26.0335 32 27.3002V32.0002H0ZM3 29.0002H29V27.3002C29 26.7669 28.8417 26.2585 28.525 25.7752C28.2083 25.2919 27.8167 24.9335 27.35 24.7002C25.2167 23.6669 23.2667 22.9585 21.5 22.5752C19.7333 22.1919 17.9 22.0002 16 22.0002C14.1 22.0002 12.25 22.1919 10.45 22.5752C8.65 22.9585 6.7 23.6669 4.6 24.7002C4.13333 24.9335 3.75 25.2919 3.45 25.7752C3.15 26.2585 3 26.7669 3 27.3002V29.0002ZM16 12.9502C17.3 12.9502 18.375 12.5252 19.225 11.6752C20.075 10.8252 20.5 9.7502 20.5 8.4502C20.5 7.1502 20.075 6.0752 19.225 5.2252C18.375 4.3752 17.3 3.9502 16 3.9502C14.7 3.9502 13.625 4.3752 12.775 5.2252C11.925 6.0752 11.5 7.1502 11.5 8.4502C11.5 9.7502 11.925 10.8252 12.775 11.6752C13.625 12.5252 14.7 12.9502 16 12.9502Z"
              fill={activeIndex === 3 ? "#EAAC00" : "black"}
            />
          </svg>
        </TabItems>
      )}
    </nav>
  );
};

const TabItems = ({ children, active, label, link }: any) => {
  return (
    <Link
      href={link}
      className="relative flex h-full flex-1 flex-col items-center justify-center hover:cursor-pointer"
    >
      {children}
      <div
        className={`m-1 text-[12px] ${
          active ? "font-semibold text-[#EAAC00]" : "font-semibold text-[#000000]"
        }`}
      >
        {label}
      </div>
    </Link>
  );
};

TabItems.displayName = "TabItems";

export default TabBarMobile;
