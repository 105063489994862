import React, { useContext, useEffect, useState, useRef } from "react";
import ListFilter from "src/components/filters/ListFilter";
import HomeOpportunities from "src/components/Feed/HomeOpportunities";
import MobileFooter from "src/components/MobileFooter";
import { SignUpModal } from "src/components/Modal";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { AppState } from "src/store";

import {
  OpportunityContext,
  OpportunityContextType,
} from "src/components/contexts/opportunityContext";

import { AccountContext, AccountContextType } from "src/components/contexts/ToggleAccountContext";

import { PageContext, PageContextType } from "src/components/contexts/PageContext";
import { filter } from "lodash";

export default function Home() {
  const { feed, showSignUpModel, setShowSignUpModel } =
    useContext<OpportunityContextType>(OpportunityContext);

  const { accountInfo, isBusiness, isLogged } = useContext<AccountContextType>(AccountContext);

  const { setBreadcrumb, setPageTitle, setIsHiddenHeader } =
    useContext<PageContextType>(PageContext);

  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const initialLoadRef = useRef(true); // To track the first load and prevent repeated loading
  const scrollPositions = useSelector((state: AppState) => state.scroll.scrollPositions);

  useEffect(() => {
    // Ensure loading runs only during the initial load
    if (initialLoadRef.current) {
      setLoading(true);
      // Simulate loading completion (replace with actual logic as needed)
      const timer = setTimeout(() => {
        setLoading(false); // Mark loading as done after fetching logic
        initialLoadRef.current = false;
      }, 600); // Add a delay or replace with an actual API call

      return () => clearTimeout(timer);
    }
  }, [isLogged]);

  // Redirect businesses to their dashboard
  useEffect(() => {
    if (isBusiness && accountInfo?.id) {
      router.replace(`/merchant/${accountInfo.id}`);
    }
  }, [accountInfo?.id, isBusiness, router]);

  // Page setup
  useEffect(() => {
    setPageTitle("");
    setBreadcrumb({ label: "", noBack: true });
    setIsHiddenHeader(false);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedPosition = scrollPositions[router.pathname];
      // Only scroll to top if we don't have a saved position
      if (savedPosition !== undefined && loading === false) {
        window.scrollTo(0, savedPosition);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [feed, scrollPositions, router.pathname, loading]); // maybe move useeffect of changeFilter to another useEffect
  // Toggle SignUp Modal
  const handleSignUpPopUp = () => {
    setShowSignUpModel((prev) => !prev);
  };

  if (isBusiness) {
    return;
  }

  return (
    <div>
      <div className="flex w-full justify-between pb-5 md:justify-center md:pb-12">
        <ListFilter />
      </div>
      <div className="mx-5 flex justify-center md:mx-0">
        <HomeOpportunities
          loading={loading}
          filterType={feed}
        />
      </div>
      <div className="md:hidden">
        <MobileFooter />
      </div>
      <SignUpModal open={showSignUpModel} onClose={handleSignUpPopUp} />
    </div>
  );
}
