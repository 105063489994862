import { STT_OK, STT_EMPTY } from "src/const/app.const";
import { createApi } from ".";
import toast from "react-hot-toast";
import { Transaction, Wallet } from "src/types/models";

export const getPaymentSecret = async (data: {
  opportunityId: string;
  giftCardId?: string;
  appliedGiftCardAmount?: number;
  quantity: number;
  slotId?: string;
}) => {
  try {
    const res = await createApi().get<{ secret: string; message: string }>("/stripe/secret", data);
    if (!res.data?.secret) {
      return {
        error: res?.data?.message || "Unknown Error",
        secret: undefined,
      };
    }
    return { secret: res.data.secret, error: undefined };
  } catch (error: any) {
    return {
      error: error.message || error,
      secret: undefined,
    };
  }
};

export const getGiftCardPaymentSecret = async (data: {
  amount: number;
  name: string;
  email: string;
  nameS: string;
  message: string;
}) => {
  try {
    const res = await createApi().get<{ secret: string; message: string }>(
      "/stripe/gift-card-secret",
      data
    );
    if (!res.data?.secret) {
      return {
        error: res?.data?.message || "Unknown Error",
        secret: undefined,
      };
    }
    return { secret: res.data.secret, error: undefined };
  } catch (error: any) {
    return {
      error: error.message || error,
      secret: undefined,
    };
  }
};

/** */
export const getWallet = async (id: string, type: string) => {
  const res = await createApi().get<{ wallet: Wallet; message?: string }>("/wallet", {
    id,
    type,
  });
  if (res.status === STT_EMPTY) {
    return { id: 0, amount: 0, previousBalance: 0, balance: 0, type };
  }
  if (res.status !== STT_OK || !res.data?.wallet) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return null;
  }
  return res.data?.wallet;
};

/**
 * IF we don't set the id, by default means current users wallet.
 * @returns
 */
export const myWallet = async () => {
  return getWallet(undefined as unknown as string, undefined as unknown as string);
};

export const getTransactions = async (data: {
  id: string;
  type: string[];
  limit?: number;
  offset?: number;
}) => {
  const res = await createApi().get<{
    results: Transaction[];
    total: number;
    message?: string;
  }>("/wallet/transactions", data);
  if (res.status === STT_EMPTY) {
    return { results: [], total: 0 };
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return { results: [], total: 0 };
  }
  return { results: res.data.results, total: res.data.total };
};

export const getPayoutInfo = async (data: { businessId: string }) => {
  const res = await createApi().get<{
    amount: number;
    nextSettle: Date;
    message?: string;
  }>("/wallet/payout", data);
  if (res.status === STT_EMPTY) {
    return { amount: 0, nextSettle: false };
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return { amount: 0, nextSettle: false };
  }
  return { amount: res.data.amount, nextSettle: res.data.nextSettle };
};

export const payWithMiki = async (data: {
  opportunityId: string;
  giftCardId?: string;
  appliedGiftCardAmount?: number;
  quantity: number;
  slotId?: string;
}) => {
  const res = await createApi().post<{
    ok: true;
    message?: string;
  }>("/pay/mikki", data);

  if (res.status === STT_EMPTY) {
    return false;
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};

export const payWithMikiGiftCard = async (data: {
  name: string;
  email: string;
  nameS: string;
  amount: number;
  message: string;
}) => {
  const res = await createApi().post<{
    ok: true;
    message?: string;
  }>("/pay/mikki/giftcard", data);

  if (res.status === STT_EMPTY) {
    return false;
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};

export const registerFreeEvent = async (data: {
  opportunityId: string;
  quantity: number;
  slotId?: string;
}) => {
  //This has to changed
  const res = await createApi().post<{
    ok: true;
    message?: string;
  }>("/pay/free-event", data);

  if (res.status === STT_EMPTY) {
    return false;
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};

export const payAllWithGiftCard = async (data: {
  opportunityId: string;
  giftCardId: string;
  quantity: number;
  slotId?: string;
}) => {
  //This has to changed
  const res = await createApi().post<{
    ok: true;
    message?: string;
  }>("/pay/giftcard-all", data);

  if (res.status === STT_EMPTY) {
    return false;
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};