import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

interface CommonInputProps {
  value?: string;
  label: string;
  disabled?: boolean;
  name?: string;
  className?: string;
  onChangeValue?: (newValue: string) => void;
  password?: boolean;
  type?: string;
  error?: string;
}

const NewCommonInput: React.FC<CommonInputProps> = ({
  value,
  label,
  disabled,
  name,
  className,
  password,
  type,
  error,
  onChangeValue,
}) => {
  const [show, setShow] = useState(!password);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="shadow-md rounded-md">
        <label
        htmlFor={"input" + label}
        className={twMerge(
            `${
            disabled ? "bg-[#D9D9D9]" : "bg-white"
            } pt[7px] relative flex h-[55px] w-full flex-col items-center justify-end overflow-hidden rounded-md border border-gray-200 px-[9px] pb-[4px] ${
            disabled ? "" : "shadow-sm"
            } ${className ?? ""}`
        )}
        >
        <input
            name={name}
            disabled={disabled}
            value={value}
            type={password && !show ? "password" : type || "text"}
            placeholder={label}
            id={"input" + label}
            onChange={(e) => {
            onChangeValue && onChangeValue?.(e.target.value);
            }}
            className="peer h-8 w-full self-end rounded-md border-none bg-transparent p-0 text-md placeholder-transparent focus:outline-none focus:ring-0"
        />
        <span className="absolute rounded-md left-[9px] top-4 -translate-y-1/2 text-sm text-[#9B9A9A] transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl peer-focus:top-4 peer-focus:text-sm">
            {label}
        </span>
        {password && (
            <div
            onClick={() => {
                setShow(!show);
            }}
            className="absolute right-3 top-1/2 -translate-y-[10px] cursor-pointer active:opacity-60 rounded-md"
            >
            <svg
                width="22"
                height="22"
                viewBox="0 0 28 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M14 0.625C7.75 0.625 2.4125 4.5125 0.25 10C2.4125 15.4875 7.75 19.375 14 19.375C20.25 19.375 25.5875 15.4875 27.75 10C25.5875 4.5125 20.25 0.625 14 0.625ZM14 16.25C10.55 16.25 7.75 13.45 7.75 10C7.75 6.55 10.55 3.75 14 3.75C17.45 3.75 20.25 6.55 20.25 10C20.25 13.45 17.45 16.25 14 16.25ZM14 6.25C11.925 6.25 10.25 7.925 10.25 10C10.25 12.075 11.925 13.75 14 13.75C16.075 13.75 17.75 12.075 17.75 10C17.75 7.925 16.075 6.25 14 6.25Z"
                fill="black"
                />
            </svg>
            </div>
        )}
        {error && (
          <div
            className={`absolute right-3 mb-2 rounded-md`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
              viewBox="0 -960 960 960">
              <path
                d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                fill="rgb(239 68 68)" />
            </svg>
          </div>
        )}
        </label>
      <span className={`absolute top-auto rounded-sm bg-gray-300 px-1 ${isHovered ? 'opacity-100' : 'opacity-0'} transition-opacity sm:w-max`}>
        {error}
      </span>
    </div>
  );
};

export default NewCommonInput;
