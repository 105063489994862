import { useDispatch } from "react-redux";
import { saveScrollPosition, saveListData, savePagination } from "src/redux/scrollReducer";
import { useRouter } from "next/router";
import { OpportunityPreview } from "../../types/models";
interface ScrollRestorationProps {
opportunities: OpportunityPreview[];
pagination: {
page: number;
hasMore: boolean;
 };
}
const useScrollRestoration = ({ opportunities, pagination }: ScrollRestorationProps) => {
const dispatch = useDispatch();
const router = useRouter();
return {
saveScrollState: () => {
dispatch(saveScrollPosition({ route: router.pathname, scrollY: window.scrollY }));
dispatch(saveListData({ route: router.pathname, data: opportunities }));
dispatch(savePagination({
 route: router.pathname,
 page: pagination.page,
 hasMore: pagination.hasMore
 }));
 }
 };
};
export default useScrollRestoration;