import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OpportunityPreview } from "../../types/models";

interface ScrollState {
  scrollPositions: Record<string, number>;
  listData: Record<string, OpportunityPreview[]>;
  pagination: Record<string, { page: number; hasMore: boolean }>;
}

const initialState: ScrollState = {
  scrollPositions: {},
  listData: {},
  pagination: {},
};

const MAX_STORED_ROUTES = 10;

const scrollSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    saveScrollPosition: (state, action: PayloadAction<{ route: string; scrollY: number }>) => {
      const routes = Object.keys(state.scrollPositions);
      if (routes.length >= MAX_STORED_ROUTES) {
        delete state.scrollPositions[routes[0]];
      }
      state.scrollPositions[action.payload.route] = action.payload.scrollY;
    },
    saveListData: (state, action: PayloadAction<{ route: string; data: OpportunityPreview[] }>) => {
      const routes = Object.keys(state.listData);
      if (routes.length >= MAX_STORED_ROUTES) {
        delete state.listData[routes[0]];
      }
      state.listData[action.payload.route] = action.payload.data;
    },
    savePagination: (state, action: PayloadAction<{ route: string; page: number; hasMore: boolean }>) => {
      const routes = Object.keys(state.pagination);
      if (routes.length >= MAX_STORED_ROUTES) {
        delete state.pagination[routes[0]];
      }
      state.pagination[action.payload.route] = { page: action.payload.page, hasMore: action.payload.hasMore };
    },
    cleanupRoute: (state, action: PayloadAction<{ route: string }>) => {
      delete state.scrollPositions[action.payload.route];
      delete state.listData[action.payload.route];
      delete state.pagination[action.payload.route];
    }
  },
});

export const { saveScrollPosition, saveListData, savePagination, cleanupRoute } = scrollSlice.actions;
export default scrollSlice.reducer;
