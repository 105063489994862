import React, { useState, useEffect, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppState } from "src/store";
import { AccountService, OpportunitiesService } from "src/services";
import useScrollRestoration from "src/hooks/useScrollRestoration";
import { FeedType } from "../contexts/opportunityContext";
import { Skeleton } from "@mui/material";
import { AccountContext, AccountContextType } from "src/components/contexts/ToggleAccountContext";
import { SearchParams, useFilterContext } from "../contexts/FilterContext/FilterContext";
import HomeCard from "./HomeCard";
import LoadingContent from "../common/LoadingContent";

export const FilterMessage = ({ filterType }: { filterType: FeedType }) => {
  const [filterMessage, setFilterMessage] = useState<{
    message: string;
    title: string;
  }>({ message: "", title: "" });
  const router = useRouter();

  useEffect(() => {
    if (filterType === FeedType.AROUND) {
      setFilterMessage({
        message: `<span>Join your <a class="text-primary underline" href="/community"> community</a> to discover the exciting opportunities available where you live.</span>`,
        title: "Discover local treasures",
      });
      return;
    }

    if (filterType === FeedType.JAZZED) {
      setFilterMessage({
        message: "As you search, click the heart icon to be alerted when opportunities arise.",
        title: "Ready to experience?",
      });
      return;
    }

    setFilterMessage({
      message: `Local businesses are now in the process of creating new opportunities. Stay tuned and get ready!`,
      title: "",
    });
  }, [filterType]);

  const handleLinkClick = (event: MouseEvent) => {
    event.preventDefault();
    const targetLink = (event.target as any).getAttribute("href");
    targetLink && router.push(targetLink);
    return;
  };

  return (
    <>
      <div
        className={
          "mt-[4rem] flex justify-center text-center font-bold md:mt-[15vh] md:text-lg lg:text-xl"
        }
      >
        {filterMessage.title}
      </div>
      {filterMessage.message && (
        <div
          className={`${
            filterMessage.title ? "mt-[1rem] md:mt-[5vh]" : "mt-[3rem] md:mt-[15vh]"
          } flex justify-center text-center md:text-lg lg:text-xl`}
        >
          <span
            dangerouslySetInnerHTML={{ __html: filterMessage?.message }}
            onClick={(e) => handleLinkClick(e)}
          />{" "}
        </div>
      )}
    </>
  );
};

export const HomeSkeleton = () => {
  return (
    <div className="h-fit w-full pt-4">
      <Skeleton variant="rectangular" height="100%" className="aspect-[1/1] w-full rounded-lg" />
      <Skeleton variant="rectangular" height={28} className="mt-6 rounded-md" />
      <Skeleton variant="rectangular" height={18} className="mt-4 rounded-md" />
      <Skeleton variant="rectangular" height={24} className="mt-4 rounded-md" />
      <Skeleton variant="rectangular" height={20} className="mt-4 rounded-md" />
    </div>
  );
};

export default function HomeOpportunities({
  loading,
  filterType,
}: {
  loading: boolean;
  filterType: FeedType;
}) {
  const router = useRouter();
  const { accountInfo, isLogged } = useContext<AccountContextType>(AccountContext);
  const { searchParams, opportunities, setOpportunities } = useFilterContext();

  // Add the Redux selector for saved data
  const scrollPositions = useSelector((state: AppState) => state.scroll.scrollPositions);
  const savedListData = useSelector((state: AppState) => state.scroll.listData[router.pathname]);
  const savedPagination = useSelector(
    (state: AppState) => state.scroll.pagination[router.pathname],
  );

  const [pagination, setPagination] = useState(savedPagination || { page: 0, hasMore: true });
  const [loading1, setLoading1] = useState(true);

  const PAGE_SIZE = 12;

  // Initialize scroll restoration hook
  const { saveScrollState } = useScrollRestoration({ opportunities, pagination });
  let savedPosition: number | undefined = scrollPositions[router.pathname];

  // Used on Around filter
  const [communityZipCode, setCommunityZipCode] = useState("");

  useEffect(() => {
    if (
      (filterType === FeedType.JAZZED && !accountInfo) ||
      (filterType === FeedType.AROUND && !communityZipCode)
    ) {
      setOpportunities([]);
      setPagination({
        page: 0,
        hasMore: false,
      });
      setLoading1(false);
      return;
    }
  }, [filterType, accountInfo, communityZipCode, setOpportunities]);

  useEffect(() => {
    savedPosition = 0;
    // Restore list data and pagination if available
    if (savedListData) {
      setOpportunities(savedListData);
    }
    if (savedPagination) {
      setPagination(savedPagination);
    }
  }, [router.pathname, scrollPositions, savedListData, savedPagination, setOpportunities]);

  // Load more opportunities
  const loadMore = useCallback(
    async ({
      page,
      searchParams,
      filterType,
    }: {
      page: number;
      searchParams: SearchParams;
      filterType: FeedType;
    }) => {
      if (
        (filterType === FeedType.JAZZED && !accountInfo) ||
        (filterType === FeedType.AROUND && !communityZipCode)
      ) {
        setOpportunities([]);
        setPagination({
          page: 0,
          hasMore: true,
        });
        setLoading1(false);
        return;
      }

      const data = {
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE,
        ...searchParams,
        latitude: searchParams.location.lat,
        longitude: searchParams.location.lng,
      };

      if (filterType === FeedType.NEW) data.minprice = Math.max(searchParams.minprice, 1);

      try {
        const res = await OpportunitiesService.getOpportunitiesFilter(filterType, data);
        if (!res) return;

        const total = res.total;
        const currentOpportunities = res.opportunities;

        setOpportunities((prevOpportunities) =>
          !page ? currentOpportunities : [...prevOpportunities, ...currentOpportunities],
        );

        setPagination((prevPagination) => ({
          ...prevPagination,
          page: page + 1,
          hasMore: total > 0 && (page + 1) * PAGE_SIZE < total,
        }));

        setLoading1(false);
      } catch (error) {
        console.error("Failed to fetch opportunities:", error);
        setLoading1(false);
      }
    },
    [isLogged, communityZipCode, setOpportunities],
  );

  // Reset Pagination on filter or login status change or the search params
  useEffect(() => {
    if (!isLogged) {
      return;
    }
    AccountService.getInfoCommunity().then((response) => {
      const userInfo = response?.data;
      if (!userInfo) {
        return;
      }
      setCommunityZipCode(userInfo?.community?.community_zipCode || "");
    });
  }, [isLogged]);

  useEffect(() => {
    const loadItems = async () => {
      await loadMore({ filterType, searchParams, page: 0 });
    };
    loadItems();
  }, [loadMore, searchParams, setOpportunities, filterType]);

  useEffect(() => {
    setLoading1(true);

    // Don't clear opportunities if we have saved data
    if (!savedListData) {
    setOpportunities([]);
    setPagination({ page: 0, hasMore: true });
    }

    const timer = setTimeout(() => {
      loadMore({ page: 0, filterType, searchParams }).finally(() => {
        setLoading1(false);
        // After loading data, check if we need to restore scroll
        if (savedPosition !== undefined) {
          window.scrollTo(0, savedPosition);
        }
      });
    }, 100);

    return () => clearTimeout(timer);
  }, [filterType]);

  useEffect(() => {
  // Only restore on initial mount
  if (savedListData && savedPosition !== undefined) {
  setOpportunities(savedListData);
  setPagination(savedPagination || { page: 0, hasMore: true });
  window.scrollTo(0, savedPosition);
  }
  }, []); // Empty dependency array for mount only

  if (loading) {
    return <LoadingContent />;
  }

  if (
    loading1 &&
    !(
      (filterType === FeedType.AROUND &&
        searchParams.location?.lat === 0 &&
        searchParams.location?.lng === 0) ||
      (filterType === FeedType.JAZZED && !accountInfo)
    )
  ) {
    return (
      <div className="safari-home relative w-full pt-10 sm:pt-6 md:pt-2">
        <div className="safari-home 3xl:grid-cols-5 relative mb-2 grid grid-cols-1 gap-x-6 gap-y-9 sm:grid-cols-2 sm:gap-y-10 sm:pt-6 md:mb-10 md:grid-cols-2 md:pt-3 ms:grid-cols-3 ml:grid-cols-4">
          {Array.from({ length: 8 }).map((_, index) => (
            <HomeSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="safari-home relative w-full pt-10 sm:pt-6 md:pt-2">
      {!opportunities.length && !loading1 && <FilterMessage filterType={filterType} />}
      <InfiniteScroll
        dataLength={opportunities.length}
        next={() => loadMore({ filterType, searchParams, page: pagination.page })}
        hasMore={pagination.hasMore}
        loader={<h4></h4>}
      >
        <div className="safari-home 3xl:grid-cols-5 relative mb-2 grid grid-cols-1 gap-x-6 gap-y-9 sm:grid-cols-2 sm:gap-y-10 sm:pt-6 md:mb-10 md:grid-cols-2 md:pt-3 ms:grid-cols-3 ml:grid-cols-4">
          {opportunities.map((opportunity, index) => (
            <div key={index} className="group relative">
              {saveScrollState && ( // Check if saveScrollState is valid
                <HomeCard
                  opportunity={opportunity}
                  imagePriority={index < 10}
                  onSaveScroll={saveScrollState}
                />
              )}
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
