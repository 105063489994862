import { createApi } from ".";

interface GiftCard {
  title: string;
  price: number;
  giverName: string;
  redeemedAt: Date | null;
  balance: number;
  createdAt?: Date;
}

interface PaginatedGiftCardResponse {
  results: GiftCard[];
  total?: number;
}

interface GetAllGiftCardsParams {
  limit: number;
  offset: number;
}

export const getGiftcardsToRedeem = async () => {
  try {
    const res = await createApi().get<GiftCard[]>('/giftcards/to-redeem');
    return { data: res.data, error: undefined };
  } catch (error: any) {
    return {
      error: error.message || error,
      data: undefined,
    };
  }
};

export const getAllGiftcards = async ({ limit, offset }: GetAllGiftCardsParams) => {
  try {
    const res = await createApi().get<PaginatedGiftCardResponse>(
      `/giftcards/getAll?limit=${limit}&offset=${offset}`
    );
    return { data: res.data, error: undefined };
  } catch (error: any) {
    return {
      error: error.message || error,
      data: undefined,
    };
  }
};

export const redeemGiftcard = async (data: {
  redeemCode1: string;
  giverName: string;
}) => {
  try {
    const res = await createApi().post<{ 
      status: number; 
      giftcard?: GiftCard;
      message?: string;
    }>('/giftcards/redeem-new', data);
    
    if (res.data.message) {
      return { error: res.data.message, data: undefined };
    }
    
    // If we have a giftcard, return it as success
    if (res.data.giftcard) {
      return { data: res.data.giftcard, error: undefined };
    }

    return { error: 'Sorry, we could not find a Gift Card.', data: undefined };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to redeem gift card';
    return {
      error: errorMessage,
      data: undefined,
    };
  }
};

export const redeemBalance = async (data: {
  giftcard: GiftCard;
  balance: number;
}) => {
  try {
    const res = await createApi().post<{ status: number; giftcard: GiftCard }>(
      '/giftcards/redeem-balance',
      data
    );
    return { data: res.data.giftcard, error: undefined };
  } catch (error: any) {
    return {
      error: error.message || error,
      data: undefined,
    };
  }
};