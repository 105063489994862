/* eslint-disable @next/next/no-html-link-for-pages */
import { Menu } from "@headlessui/react";
import Link from "next/link";
import { AccountContext, AccountContextType } from "../contexts/ToggleAccountContext";
import { Fragment, useContext } from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavLoggedInMenu({ unreadMessages }: { unreadMessages: boolean }) {
  const {
    accountInfo,
    logout,
    checkHasNewFeed,
    isBusiness,
    businessFeedStatus,
    businessMessageStatus,
  } = useContext<AccountContextType>(AccountContext);

  return (
    <Fragment>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/opportunities"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block px-4 py-4 text-center text-sm font-semibold text-black",
            )}
          >
            Opportunities
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/community"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block border-t-2 px-4 py-4 text-center text-sm font-semibold text-black",
            )}
          >
            <div className="relative">
              Community
              {((isBusiness && businessFeedStatus[accountInfo.id]) ||
                (!isBusiness && checkHasNewFeed)) && (
                <div className="brand-color-semi-transparent absolute right-1 top-[3px] inline-flex h-3 w-3 rounded-full"></div>
              )}
            </div>
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/messages"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              " block  border-t-2 px-4 py-4 text-center text-sm font-semibold text-black",
            )}
          >
            <div className="relative">
              Messages
              {((isBusiness && businessMessageStatus[accountInfo.id]) ||
                (!isBusiness && unreadMessages > 0)) && (
                <div className="brand-color-semi-transparent absolute right-1 top-[3px] inline-flex h-3 w-3 rounded-full"></div>
              )}
            </div>
          </Link>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <Link
            href="/account"
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block border-t-2  px-4 py-4 text-center text-sm text-black",
            )}
          >
            Account
          </Link>
        )}
      </Menu.Item>
      {!isBusiness && (
      <Menu.Item>
       {({ active }) => (
        <Link
          href="/giftcards"
          className={classNames(
          active ? "hover:brand-color-opaque" : "",
          "block border-t-2 px-4 py-4 text-center text-sm text-black"
          )}
            >
           Gift cards
         </Link>
         )}
      </Menu.Item>
        )}
      <Menu.Item>
        {({ active }) => (
          <button
            className={classNames(
              active ? "hover:brand-color-opaque" : "",
              "block w-full border-t-2 px-4 py-4 text-center text-sm text-black",
            )}
            onClick={() => {
              logout();
              window.location.pathname === "/";
            }}
          >
            Log out
          </button>
        )}
      </Menu.Item>
    </Fragment>
  );
}
