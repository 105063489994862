import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import dealReducer from "./redux/deal/dealReducer";
import messageReducer from "src/redux/message.reducer";
import opportunityReducer from "./redux/opportunities/opportunityReducer";
import scrollReducer from "./redux/scrollReducer"; // Import your new scrollSlice reducer

const store = configureStore({
  reducer: {
    opportunity: opportunityReducer,
    dealForm: dealReducer,
    message: messageReducer,
    scroll: scrollReducer, // Add scroll reducer here
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;